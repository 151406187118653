//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './css/font.css'
import HomeView from './components/HomeView.vue'
import OrderView from './components/OrderView.vue'
import icon_my from '@/assets/icon_my.png'
// import icon_my_on from '@/assets/icon_my_on.png'
import icon_my_on from '@/assets/my_on.gif'
import icon_order from '@/assets/icon_order.png'
// import icon_order_on from '@/assets/icon_order_on.png'
import icon_order_on from '@/assets/order_on.gif'
export default {
  name: "Home",
  data() {
    return {
      list: [],
      tabActive:0,
      icon_my:icon_my,
      icon_my_on:icon_my_on,
      icon_order:icon_order,
      icon_order_on:icon_order_on
    };
  },
  components: { HomeView, OrderView },
  created() {
    console.log('icon_orde111111r',icon_order)
  },
  
  mounted() {
    console.log('icon_order',icon_order)
    window.document.title = "全员营销";

  },
  methods: {


    goPage(url){
      console.log(url);
      this.$router.push(url)
    }
  },
};
